import React from "react";

import "./Jobs.scss";

const Jobs = () => {
    const staticDesc = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.";

    const jobs = [
        {
            id: 1,
            name: "Account Manager",
            description: staticDesc,
        },
        {
            id: 2,
            name: "Office Assistant",
            description: staticDesc,
        },
    ]

    return(
        <div className="jobs">
            { jobs.map((job, i) => 
                <div key={i} className="job">
                    <p>{job.name}</p>
                    <span>{job.description}</span>
                </div>
            )}
        </div>
    )
}

export default Jobs;