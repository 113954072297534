import React from "react";
import "./JobHero.scss";

const HeroSection = () => {
  return (
    <div className="jobs-hero">
      <div className="hero-img"></div>
      <div className="hero-text-con d-flex flex-row">
        <div className="waw-headline">
          <p className="p1">Careers</p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
