import * as React from "react";
import HeroSection from "../components/Careers/HeroSection";
import Jobs from "../components/Careers/Jobs";
// import Inquiries from "../components/Home/Inquiries";
// import { Link } from "gatsby"

import Layout from "../components/layout";
import Seo from "../components/seo";

const Careers = () => (
  <Layout>
    <Seo title="Careers" />
    <HeroSection />
    <Jobs />
    {/* <Inquiries /> */}
  </Layout>
);

export default Careers;
